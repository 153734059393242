import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import { ChangeEventHandler } from "react";

interface Props {
  label?: string;
  labelClassName?: string;
  type?: string;
  placeholder?: string;
  htmlFor?: string;
  name: string;
  id: string;
  value: string;
  valid: Boolean | null;
  onChange: ChangeEventHandler;
  className?: string;
  onBlur?: Function;
  validColor?: string;
  showPassword?: boolean;
  toggleShowPassword?: Function;
  labelLeftSide?: boolean;
  showIcon?: boolean;
  errorMessage?: string;
}

const InputField = (props: Props) => {
  const {
    label,
    value,
    valid,
    onChange,
    type = "text",
    id,
    placeholder = "",
    className = "block w-full pr-10 focus:outline-none sm:text-sm rounded-md",
    name,
    htmlFor = "",
    labelClassName = "",
    onBlur,
    validColor = "",
    showPassword = false,
    toggleShowPassword,
    labelLeftSide = false,
    showIcon = true,
    errorMessage = "",
  } = props;

  const eyeBall = () => {
    if (!showPassword) {
      return (
        <button
          type="button"
          onClick={() =>
            toggleShowPassword ? toggleShowPassword(!showPassword) : null
          }
          className={`absolute inset-y-0 right-0 ${
            valid === false ? "pr-10" : "pr-3"
          } flex items-center`}
        >
          <EyeIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      );
    }

    return (
      <button
        type="button"
        onClick={() =>
          toggleShowPassword ? toggleShowPassword(!showPassword) : null
        }
        className={`absolute inset-y-0 right-0 ${
          valid === false ? "pr-10" : "pr-3"
        } flex items-center`}
      >
        <EyeOffIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    );
  };

  const inputFieldRender = () => {
    return (
      <>
        <div
          className={`${
            type === "tel" ? "mt-1 flex" : "relative"
          } rounded-md shadow-sm`}
        >
          {type === "tel" ? (
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              +1
            </span>
          ) : null}
          <input
            type={showPassword && type === "password" ? "text" : type}
            name={name}
            id={id}
            className={`${className} ${
              valid === null
                ? ""
                : valid === true
                ? validColor
                : "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
            }`}
            placeholder={placeholder}
            value={value}
            aria-invalid={valid ? true : false}
            aria-describedby={`${label} error`}
            onChange={onChange}
            onBlur={(e) => (onBlur ? onBlur(e) : null)}
          />
        </div>
        {type === "password" ? eyeBall() : null}
        {valid === null ? null : valid === true ? null : showIcon ? (
          <div
            className={
              labelLeftSide
                ? `flex items-center pointer-events-none`
                : `absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none`
            }
          >
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="rounded-md bg-red-600 py-2 text-white px-4 mt-2 sm:mt-0">
            {errorMessage}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {label ? (
        labelLeftSide ? null : (
          <label htmlFor={htmlFor} className={labelClassName}>
            {label}
          </label>
        )
      ) : null}

      {labelLeftSide ? (
        <>
          <label htmlFor={htmlFor} className={labelClassName}>
            {label}
          </label>
          {inputFieldRender()}
        </>
      ) : (
        <div className="relative rounded-md shadow-sm">
          {inputFieldRender()}
        </div>
      )}
    </>
  );
};

export default InputField;
