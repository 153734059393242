import { DateTime, Duration } from "luxon";
import React, { useEffect, useState } from "react";

interface Params {
  quotedTime: number;
}

function TimeCounter(props: Params) {
  const { quotedTime } = props;

  const [count, setCount] = useState("");

  const translateTime = (time: string) => {
    if (time.includes("-")) {
      let formatted;
      formatted = "+" + time.replaceAll("-", "");
      return formatted;
    }
    else return time;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      let calc = Duration.fromMillis(
        quotedTime - DateTime.local().toMillis()
      ).toFormat("h:mm:ss");
      while (calc.charAt(0) === "0" || calc.charAt(0) === ":") {
        calc = calc.substring(1);
      }
      setCount(calc);
    }, 1000);

    return () => clearInterval(interval);
  }, [quotedTime]);

  return (
    <div className={`${translateTime(count).includes("+") ? "text-red-500" : "text-green-600"}`}>
        {translateTime(count)}
    </div>
  );
}

export default TimeCounter;
