import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import QuantityInput from "../shared/QuantityInput";
import { WaitSelect } from "../shared/WaitSelect";
import { useParams } from "react-router-dom";
import {
  doc,
  DocumentData,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "libphonenumber-js";

interface Params {
  open: boolean;
  setClose: () => void;
  party?: DocumentData;
}

const EditPartyModal = (props: Params) => {
  const { open, setClose, party } = props;
  const [name, setName] = useState("");
  const [quantity, setQuanity] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [waitQuote, setWaitQuote] = useState<number>(-1);
  const [notes, setNotes] = useState("");
  const [hereCheck, setHereCheck] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [notifySelection, setNotifySelection] = useState(false);

  const cancelButtonRef = useRef(null);

  const db = getFirestore();
  const { restaurantId } = useParams();
  const functions = useFunctions();

  const sendMessageToCustomer = httpsCallable(
    functions,
    "textMessageFunctions-sendTextMessage"
  );

  // updates the Party's status to 'notified' and sends a text message notifying them that their table is ready
  const updateParty = async (textParty: boolean) => {
    let partyRef = doc(db, `Restaurants/${restaurantId}/Orders`, party?.id);

    console.log("text party value", textParty);

    await updateDoc(
      partyRef,
      waitQuote !== party?.quotedWait - party?.timeOfQuote
        ? {
            name: name,
            size: quantity,
            quotedWait: DateTime.now().toMillis() + waitQuote,
            timeOfQuote: DateTime.now().toMillis(),
            notes: notes,
            customerIsHere: hereCheck,
          }
        : {
            name: name,
            size: quantity,
            notes: notes,
            customerIsHere: hereCheck,
          }
    ).then(async () => {
      toast.success(
        <div className="-ml-3">
          <span className=" font-bold text-black">{name}'s </span>
          <span>info has been updated!</span>
        </div>,
        {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          closeButton: false,
          draggable: true,
          progress: undefined,
          toastId: "item-added",
          className:
            "!border-l-8 !border-l-eatout-notification-green !rounded-l-lg mt-6",
          bodyClassName: "",
          icon: (
            <svg
              width="20"
              height="17"
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z"
                fill="#45AF2D"
              />
            </svg>
          ),
        }
      );
      // Sending confirmation text with document data
      if (textParty) {
        await sendMessageToCustomer({
          hostMessage: "Your party info has been updated!",
          phone: party?.phone,
          restaurantId: restaurantId,
          orderId: party?.id,
        }).then((res: any) => {
          if (res?.data?.error) {
            toast.error("Error sending update confirmation text");
          }
        });
      }
    });
  };

  const handleChange = (event: any, type: string) => {
    switch (type) {
      case "name":
        setName(event.target.value);
        break;
      case "phoneNumber":
        setPhoneNumber(event.target.value);
        break;
      case "notes":
        setNotes(event.target.value);
        break;
    }
  };

  const handleCheckbox = (event: any, type: string) => {
    switch (type) {
      case "here-check":
        const checked = event?.target?.checked;
        setHereCheck(checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log("validating this info", {
      name,
      quantity,
      phoneNumber,
      waitQuote,
    });
    if (
      name &&
      quantity > 0 &&
      isValidPhoneNumber(phoneNumber, "US") &&
      waitQuote >= 0
    ) {
      setFormValid(true);
    } else setFormValid(false);
  }, [name, phoneNumber, quantity, waitQuote]);

  useEffect(() => {
    console.log("party info was loaded", party);
    setName(party?.name);
    setPhoneNumber(party?.phone);
    setWaitQuote(party?.quotedWait - party?.timeOfQuote);
    setHereCheck(party?.customerIsHere);
    setQuanity(party?.size);
    setNotes(party?.notes);
  }, [party]);

  useEffect(() => {
    console.log("open state changed", cancelButtonRef);
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment} appear={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {}}
        open={open}
        initialFocus={cancelButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-bold leading-6 text-gray-900 text-left mb-6"
                    >
                      Edit {party?.name}'s Info
                    </Dialog.Title>
                    <div className="mt-2 grid grid-cols-2 gap-6 mb-7">
                      <div className="flex flex-col text-left space-y-2">
                        <label htmlFor="name" className="font-bold">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="p-2 h-10 border border-black rounded-md w-60"
                          value={name}
                          onChange={(e) => handleChange(e, "name")}
                        />
                      </div>
                      <div className="flex flex-col text-left space-y-2">
                        <label htmlFor="phoneNumber" className="font-bold">
                          Phone Number (No edit)
                        </label>
                        <input
                          type="text"
                          id="phoneNumber"
                          className="p-2 h-10 border border-black rounded-md w-60"
                          value={phoneNumber}
                          onChange={(e) => handleChange(e, "phoneNumber")}
                          disabled={true}
                        />
                      </div>
                      <div className="flex flex-col text-left space-y-2">
                        <label htmlFor="" className="font-bold ">
                          Wait Time
                        </label>
                        <WaitSelect
                          quotedWait={waitQuote}
                          setQuotedWait={setWaitQuote}
                          editing={true}
                        />
                      </div>
                      <div className="flex flex-col text-left space-y-2">
                        <div className="flex h-6">
                          <label htmlFor=""></label>
                        </div>
                        <div className="flex items-center h-full space-x-2">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-6 w-6 text-primary focus:ring-primary border-gray-300 rounded"
                            defaultChecked={party?.customerIsHere ? party.customerIsHere : false}
                            onChange={(e) => {
                              handleCheckbox(e, "here-check");
                            }}
                          />
                          <label htmlFor="" className="font-bold">
                            Are they here?
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col text-left mb-10">
                      <p className="font-bold">How many people in the party?</p>
                      <QuantityInput
                        quantity={quantity}
                        setQuantity={setQuanity}
                      />
                    </div>
                    <div className="flex flex-col text-left space-y-2">
                      <label htmlFor="notes" className="font-bold">
                        Anything we should know about?
                      </label>
                      <textarea
                        className="w-full bg-slate-100 rounded-md resize-none pt-2 px-3 h-28"
                        onChange={(e) => handleChange(e, "notes")}
                        name=""
                        value={notes}
                        id="notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
                {notifySelection ? (
                  <>
                    <p className="flex mt-2 text-lg font-bold text-secondary">
                      Send update confirmation to customer?
                    </p>
                    <div className="flex mt-2 space-x-3">
                      <button
                        type="button"
                        ref={cancelButtonRef}
                        autoFocus
                        className={`flex w-1/2 justify-center rounded-2xl h-11 items-center font-bold border border-black bg-white
                        px-4 py-2 text-lg text-black shadow-sm active:brightness-75`}
                        onClick={() => {
                          updateParty(false);
                          setNotifySelection(false);
                          setClose();
                        }}
                      >
                        No
                      </button>
                      <button
                        type="button"
                        disabled={!formValid}
                        className={`flex w-1/2 justify-center rounded-2xl h-11 items-center font-bold border border-black bg-white
                         px-4 py-2 text-lg text-black shadow-sm active:brightness-75`}
                        onClick={() => {
                          updateParty(true);
                          setNotifySelection(false);
                          setClose();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="flex mt-5 sm:mt-6 space-x-3">
                    <button
                      type="button"
                      ref={cancelButtonRef}
                      autoFocus
                      className={`inline-flex w-1/4 hover:cursor-pointer justify-center rounded-2xl h-11 items-center font-bold border border-transparent bg-slate-300 px-4 py-2 text-lg text-black shadow-sm active:brightness-75`}
                      onClick={() => {
                        setClose();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={!formValid}
                      className={`inline-flex w-full justify-center rounded-2xl h-11 items-center font-bold border border-transparent ${
                        formValid ? "bg-secondary" : "bg-slate-300"
                      }  px-4 py-2 text-lg text-white shadow-sm active:brightness-75`}
                      onClick={() => {
                        setNotifySelection(true);
                      }}
                    >
                      Update Party
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditPartyModal;
