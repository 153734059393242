import { DocumentData } from "firebase/firestore";
import { useEffect, useState } from "react";
import NotesModal from "./NotesModal";
import { RequestRow } from "./RequestRow";
import { Howl } from "howler";
import smsNotification from "../../assets/audio/notification.mp3";

interface Params {
  requests?: DocumentData[];
}

const RequestsTable = (props: Params) => {
  const { requests } = props;

  const [activeNotes, setActiveNotes] = useState("");
  const [notesOpen, setNotesOpen] = useState(false);

  const sound = new Howl({
    src: [smsNotification],
  });
  useEffect(() => {
    if (requests?.length) {
      sound.play();
    }
  }, [requests?.length]);

  return (
    <>
      <div className="flex flex-col w-full mb-11">
        <h2 className="flex w-full border-b font-bold border-black items-baseline">
          REQUESTS{" "}
          {requests?.length ? (
            requests?.length > 1 ? (
              <span className="flex bg-red-600 rounded-full w-5 h-5 text-center justify-center items-center text-white ml-2">
                {requests?.length}
              </span>
            ) : (
              <span className="flex bg-black rounded-full w-5 h-5 text-center justify-center items-center text-white ml-2">
                {requests?.length}
              </span>
            )
          ) : null}
        </h2>
        <div className="mt-8 flex flex-col rounded-lg shadow-lg border border-slate-200">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden md:rounded-t-2xl">
                <div className="w-full max-h-52 min-h-[60px] overflow-auto">
                  <table className="min-w-full divide-y divide-slate-300">
                    <thead className="bg-white top-0 sticky">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                        >
                          NAME
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                        >
                          SIZE
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 w-1/4"
                        >
                          NOTES
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                        >
                          REQUEST SUBMITTED
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                        >
                          WAIT QUOTE
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white text-left">
                      {requests ? (
                        <RequestRow
                          key={requests[0].id}
                          request={requests[0]}
                          setNotesOpen={setNotesOpen}
                          setActiveNotes={setActiveNotes}
                        />
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotesModal open={notesOpen} setOpen={setNotesOpen} notes={activeNotes} />
    </>
  );
};

export default RequestsTable;
