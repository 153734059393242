interface Params {
  disabled?: boolean;
  showSubmissionNotification?: boolean
}

const OrderIcon = (props: Params) => {
  const { disabled, showSubmissionNotification } = props;

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6667 0H3.33333C1.5 0 0 1.5 0 3.33333V26.6667C0 28.5 1.5 30 3.33333 30H26.6667C28.5 30 30 28.5 30 26.6667V3.33333C30 1.5 28.5 0 26.6667 0ZM6.66667 6.66667H10V10H6.66667V6.66667ZM6.66667 13.3333H10V16.6667H6.66667V13.3333ZM6.66667 20H10V23.3333H6.66667V20ZM23.3333 23.3333H13.3333V20H23.3333V23.3333ZM23.3333 16.6667H13.3333V13.3333H23.3333V16.6667ZM23.3333 10H13.3333V6.66667H23.3333V10Z"
        fill={showSubmissionNotification ? "#2A6041" : disabled ? "#e9e9e9" : "black"}
      />
    </svg>
  );
};

export default OrderIcon;
