const QuantityInput = ({
    quantity,
    setQuantity,
    styling,
    onChange
  }: {
    quantity: number;
    setQuantity: Function;
    styling?: string;
    onChange?: Function;
  }) => {
    return (
      <div className={`flex flex-row pt-2 ${styling}`}>
        <button
          className="flex"
          disabled={quantity <= 1}
          onClick={() => setQuantity(--quantity)}
        >
          <svg
            width="48"
            height="56"
            viewBox="0 0 48 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="-mt-1.5"
          >
            <circle cx="24" cy="31" r="23.75" stroke="black" strokeWidth="0.5" />
            <path
              d="M29.5718 30.032V33.008H17.4278V30.032H29.5718Z"
              fill="black"
            />
          </svg>
        </button>
        <p className="flex text-3xl mx-4 self-center font-light w-6 justify-center">{quantity}</p>
        <button className="flex" onClick={() => setQuantity(++quantity)}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mt-0.5"
          >
            <circle cx="24" cy="24" r="23.75" stroke="black" strokeWidth="0.5" />
            <path
              d="M23.5375 16.5H25.4575V23.54H32.4975V25.46H25.4575V32.5H23.5375V25.46H16.4975V23.54H23.5375V16.5Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    );
  };
  
  export default QuantityInput;