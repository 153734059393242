import { Switch } from "@headlessui/react";
import { doc, DocumentData, getFirestore, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useFirestoreDocData, useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import WaitWarning from "./WaitWarning";
import { toast } from "react-toastify";

interface Params {
  restaurantInfo: DocumentData;
  setAddWaitOpen: (state: boolean) => void;
  searchText: string;
  setSearchText: (state: string) => void;
  printerConnectionStatus: boolean;
  printerConnecting: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Header = (props: Params) => {
  const {
    restaurantInfo,
    setAddWaitOpen,
    searchText,
    setSearchText,
    printerConnectionStatus,
    printerConnecting,
  } = props;

  const functions = useFunctions();

  const { restaurantId } = useParams();
  const db = getFirestore();
  const restaurantRef = doc(db, `Restaurants/${restaurantId}`);
  const { data: restaurant } = useFirestoreDocData(restaurantRef);

  // call firebase function to generate menu
  const generateMenu = async () => {
    const generateMenu = httpsCallable(
      functions,
      "scheduledFunctions-generateMenu"
    );
    await generateMenu({ restaurantId: restaurantId })
      .then((result) => {
        if (result){
          toast.success(
            <div className="text-left pl-2 -ml-3">
              Menu generated successfully
            </div>,
            {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              closeButton: false,
              draggable: true,
              progress: undefined,
              toastId: "item-added",
              className:
                "!border-l-8 !border-l-eatout-notification-green !rounded-l-lg mt-6",
              bodyClassName: "",
              icon: (
                <svg
                  width="20"
                  height="17"
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z"
                    fill="#45AF2D"
                  />
                </svg>
              ),
            }
          );
        }
        // returns 0 if error
        else {
          toast.error("There was a problem generating the menu");
        }
      })
      .catch((error) => {
        toast.error("There was a problem generating the menu");
      });
  };

  const updateWaitlist = async (status: boolean) => {
    await updateDoc(restaurantRef, {
      activeWait: status,
    });
  };

  return (
    <>
      <div className="flex flex-col w-full bg-white px-8 items-center">
        {/* <div className='flex bg-slate-500 w-36 h-full'></div> */}
        <div className="flex w-full items-center space-x-3 mt-2 py-4 border-b-[1px] border-gray-200 justify-between">
          <h1 className="text-4xl font-extrabold">
            {`${restaurantInfo?.name ? restaurantInfo?.name : ""} Waitlist`}
          </h1>
          <div className={`px-1 py-1`}>
            <div className="flex flex-row">
              Printer Connection Status:{" "}
              {printerConnecting ? (
                <span className="text-black text-lg font-bold inline ml-1 -mt-[1px]">
                  Connecting
                  <svg
                    className="animate-spin h-5 w-5 ml-2 inline"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="#2A6041"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              ) : printerConnectionStatus ? (
                <span className="text-[#2A6041] text-lg font-bold inline ml-1 -mt-[1px]">
                  Connected
                </span>
              ) : (
                <span className="text-red text-lg font-bold inline ml-1 -mt-[1px]">
                  Not Connected
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full py-4 items-center space-x-10">
          <input
            type={"text"}
            placeholder={"Search waitlist"}
            className="rounded-md placeholder:text-slate-600 px-3 py-1 w-[40%] border-[1px] bg-slate-200 h-12"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            maxLength={30}
          />
          <div className="flex items-center space-x-3">
            <Switch
              checked={restaurant?.activeWait}
              onChange={() => updateWaitlist(!restaurant?.activeWait)}
              className={classNames(
                restaurant?.activeWait ? "bg-primary" : "bg-gray-200",
                "relative inline-flex h-7 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                className={classNames(
                  restaurant?.activeWait ? "translate-x-4" : "translate-x-0",
                  "pointer-events-none relative inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              >
                <span
                  className={classNames(
                    restaurant?.activeWait
                      ? "opacity-0 ease-out duration-100"
                      : "opacity-100 ease-in duration-200",
                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-3 w-3 text-gray-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span
                  className={classNames(
                    restaurant?.activeWait
                      ? "opacity-100 ease-in duration-200"
                      : "opacity-0 ease-out duration-100",
                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-3 w-3 text-primary"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
            <p>
              {restaurant?.activeWait
                ? "Accepting Customers"
                : "Not Accepting Customers"}
            </p>
          </div>
          <div className="flex flex-grow" />
          <button
            className={`px-4 py-1 min-h-12 border-[1px] rounded-md ${
              restaurant?.activeWait ? "bg-primary" : "bg-slate-200"
            }  font-bold text-xl`}
            disabled={!restaurant?.activeWait}
            onClick={() => setAddWaitOpen(true)}
          >
            Add to Waitlist
          </button>
          <button
            className={`px-4 py-1 min-h-12 border-[1px] rounded-md bg-primary font-bold text-xl`}
            onClick={() => generateMenu()}
          >
            Generate Menu
          </button>
        </div>
      </div>
      {!restaurant?.activeWait ? <WaitWarning /> : null}
    </>
  );
};

export default Header;
