import { Transition, Dialog } from "@headlessui/react";
import React, { Fragment } from "react";

interface Params {
    open: boolean,
    setOpen: (state: boolean) => void;
    notes?: string
}

function NotesModal(props: Params) {
  const {open, setOpen, notes} = props;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-8 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-10 text-left shadow-xl w-fit transition-all max-h-[70vh]">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-bold leading-6 text-secondary text-left mb-8"
                    >
                      Notes
                    </Dialog.Title>
                  </div>
                  <div className="flex md:w-[50vw] sm:w-[90vw] h-56 overflow-y-auto overflow-x-hidden bg-slate-100 border border-slate-200 rounded-lg p-5">
                    <p className="flex flex-wrap w-full">
                      {notes ? notes : null}
                    </p>
                  </div>
                  <div className="flex mt-9 space-x-9 justify-end">
                    <button
                      className="px-3 py-2 w-32 bg-black rounded-md text-white font-bold"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default NotesModal;
