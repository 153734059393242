import { Transition, Dialog } from "@headlessui/react";
import { doc, DocumentData, getFirestore, updateDoc } from "firebase/firestore";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Params {
  party: DocumentData;
  open: boolean;
  setOpen: () => void;
  setClose: () => void;
  closeParent?: () => void;
  print: any;
}

function PrintConfirmationModal(props: Params) {
  const { open, setClose, party, closeParent, print } = props;

  const { restaurantId } = useParams();
  const db = getFirestore();

  const [tableNumber, setTableNumber] = useState(party?.tableNumber || "");

  const processPrint = async () => {
    console.log("tableNumber in print process: ", tableNumber);
    print(party, tableNumber);

    let partyRef = doc(db, `Restaurants/${restaurantId}/Orders`, party?.id);
    await updateDoc(partyRef, {
      tableNumber: tableNumber,
      submitted: true,
      printed: true,
    });
    setClose();
    toast.success(
      <div className="-ml-3">
        <span className=" font-bold text-black">{party?.name}'s </span>
        <span>order has been printed!</span>
      </div>,
      {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        closeButton: false,
        draggable: true,
        progress: undefined,
        toastId: "item-added",
        className:
          "!border-l-8 !border-l-eatout-notification-green !rounded-l-lg mt-6",
        bodyClassName: "",
        icon: (
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z"
              fill="#45AF2D"
            />
          </svg>
        ),
      }
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-8 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden max-w-lg rounded-lg bg-white p-10 text-left shadow-xl w-fit transition-all max-h-[70vh]">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    {party?.printed ? (
                      <Dialog.Title
                        as="h3"
                        className="text-3xl font-bold leading-6 text-secondary text-left mb-8"
                      >
                        Duplicate print!
                      </Dialog.Title>
                    ) : (
                      <Dialog.Title
                        as="h3"
                        className="text-3xl font-bold leading-6 text-secondary text-left mb-8"
                      >
                        Are you sure you want to print?
                      </Dialog.Title>
                    )}
                  </div>
                  <div className="flex w-full max-h-56 overflow-y-auto overflow-x-hidden pr-3">
                    {party?.printed ? (
                      <p>This order has already been printed. Are you sure you would like to print again?</p>
                    ) : (
                      <p>
                        Printing this ticket will lock in{" "}
                        <span className="font-bold">{party?.name}'s </span>{" "}
                        order and they will not be able to make changes. Are you
                        sure you would like to print?
                      </p>
                    )}
                  </div>
                  <div className="pt-9">
                    <label htmlFor="tableNumber" className="mr-2 font-bold">Table Number</label>
                    <input
                      type="text"
                      id="tableNumber"
                      className="p-2 h-10 border border-black rounded-md w-60"
                      value={tableNumber}
                      onChange={(e) => setTableNumber(e.target.value)}
                    />
                  </div>
                  <div className="flex mt-9 space-x-9">
                    <button
                      className="py-2 rounded-md"
                      onClick={() => {
                        setClose();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={`px-6 py-2 rounded-md bg-black text-white font-bold disabled:bg-gray-500 flex`}
                      onClick={() => {
                        processPrint();
                        if (closeParent) {
                          closeParent();
                        }
                      }}
                    >
                      Yes, Print Order
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PrintConfirmationModal;
