import { Dialog, Transition } from "@headlessui/react";
import { doc, DocumentData, getFirestore, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFunctions } from "reactfire";

interface Params {
  open: boolean;
  setOpen: (state: boolean) => void;
  party: DocumentData | null;
  print: any;
  printerConnectionStatus: boolean;
}

const NotifyModal = (props: Params) => {
  const { open, setOpen, party, print, printerConnectionStatus } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [tableNumber, setTableNumber] = useState(party?.tableNumber || "");
  const functions = useFunctions();
  const { restaurantId } = useParams();
  const sendMessageToCustomer = httpsCallable(
    functions,
    "textMessageFunctions-notifyCustomerOfSeating"
  );

  const handleNotifyCustomer = async (printOrder: boolean) => {
    // Setting party to 'notified' and sending a notification text
    setOpen(false);

    console.log("party: ", party);
    console.log("tableNumber: ", tableNumber);

    const db = getFirestore();
    const partyRef = doc(db, `Restaurants/${restaurantId}/Orders`, party?.id);

    if (printOrder && printerConnectionStatus) {
      print(party);
    } else if (printOrder && !printerConnectionStatus) {
      toast.error(
        "Printer is not connected. Please connect printer and manually print order."
      );
    }

    await updateDoc(partyRef, {
      status: "notified",
      tableNumber: tableNumber,
    }).then(() => {
      toast.promise(
        sendMessageToCustomer({
          phone: party?.phone,
          restaurantId: restaurantId,
          orderId: party?.id,
        }),
        {
          pending: `Notifying ${party?.name} now!`,
          success: `${party?.name} has been notified!`,
          error: `There was a problem messaging ${party?.name}`,
        }
      );
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-8 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-10 text-left shadow-xl w-fit transition-all max-h-[70vh]">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-bold leading-6 text-secondary text-left mb-8"
                    >
                      The table is ready!
                    </Dialog.Title>
                  </div>
                  <div className="flex w-full max-h-56 overflow-y-auto overflow-x-hidden pr-3">
                    <p>
                      Would you like to notify{" "}
                      <span className="font-bold">{party?.name}</span> via text
                      that their table is ready?
                    </p>
                  </div>
                  {party?.items?.length ? (
                    <div className="pt-9">
                      <label htmlFor="tableNumber" className="mr-2 font-bold">
                        Table Number
                      </label>
                      <input
                        type="text"
                        id="tableNumber"
                        className="p-2 h-10 border border-black rounded-md w-60"
                        value={tableNumber}
                        onInput={(e) => {
                          // @ts-ignore
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onChange={(e) => setTableNumber(e.target.value)}
                      />
                    </div>
                  ) : null}

                  <div className="flex mt-9 space-x-9 justify-between">
                    <button
                      className="py-2 rounded-md"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-6 py-2 rounded-md bg-secondary text-white font-bold disabled:bg-gray-500 flex"
                      disabled={
                        showSpinner || (party?.items?.length && !tableNumber)
                      }
                      onClick={() => {
                        handleNotifyCustomer(
                          party?.items?.length ? true : false
                        );
                      }}
                    >
                      {showSpinner ? (
                        <>
                          Notifying customer
                          <svg
                            className="animate-spin h-5 w-5 ml-3 mt-0.5"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </>
                      ) : (
                        <>
                          {party?.items?.length
                            ? "Yes, notify and print order"
                            : "Yes, notify customer"}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NotifyModal;
