import { DocumentData } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import CurrentWaitRow from "./CurrentWaitRow";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import MessageView from "./MessageView";
import OrderModal from "./OrderModal";

interface Params {
  initialState: any;
  setInitialState: (state: any) => void;
  waitlist?: DocumentData[];
  restaurantInfo: DocumentData;
  searchText: string;
  print: any;
  printerStatus: boolean;
  connectPrinter: () => void;
}

const CurrentWaitTable = (props: Params) => {
  const {
    initialState,
    setInitialState,
    waitlist,
    restaurantInfo,
    searchText,
    print,
    printerStatus,
    connectPrinter
  } = props;
  const [orderOpen, setOrderOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [activeParty, setActiveParty] = useState<DocumentData>({});

  // Used to refresh the ordered items list in the OrderModal
  // an activeParty state is used as a means of avoiding having to instantiate an OrderModal in the DOM for every party on the waitlist, now only one OrderModal is required
  useEffect(() => {
    if (activeParty) {
      let foundParty = waitlist?.find((x) => x.id === activeParty.id);
      if (foundParty) {
        setActiveParty(foundParty);
      }
    }
  }, [waitlist, activeParty]);

  const filterWaitlistWithSearchText = useCallback(() => {
    if (searchText?.length > 1) {
      return waitlist?.filter((x) => {
        // Filter by name
        return x.name.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      return waitlist;
    }
  }, [searchText, waitlist]);

  return (
    <>
      <div className="rounded-xl shadow-lg border-slate-200 mt-8 border isolate overflow-hidden">
        <div className="overflow-auto max-h-[500px] rounded-xl">
          <table className="min-w-full divide-y divide-slate-300 rounded-xl table-fixed">
            <thead className="bg-white top-0 sticky">
              <tr className="w-full">
                <th
                  scope="col"
                  className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600 sm:pl-6"
                >
                  Order
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
                >
                  Size
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
                >
                  Wait Quoted
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
                >
                  Time Left
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600 w-[30%]"
                >
                  Notes
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white text-left">
              {filterWaitlistWithSearchText()?.map((x) => (
                <CurrentWaitRow
                  initialState={initialState}
                  setInitialState={setInitialState}
                  key={x.id}
                  party={x}
                  setActiveParty={setActiveParty}
                  setMessageOpen={setMessageOpen}
                  setOrderOpen={setOrderOpen}
                  setDeleteOpen={setDeleteOpen}
                  print={print}
                  printerConnectionStatus={printerStatus}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <OrderModal open={orderOpen} setOpen={setOrderOpen} party={activeParty} print={print} printerStatus={printerStatus} connectPrinter={connectPrinter} />
      <MessageView
        open={messageOpen}
        setOpen={setMessageOpen}
        party={activeParty}
        restaurantInfo={restaurantInfo}
        print={print}
        printerConnectionStatus={printerStatus}
      />
      <DeleteConfirmationModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        party={activeParty}
      />
    </>
  );
};

export default CurrentWaitTable;
