import { DocumentData } from "firebase/firestore";
import { Duration } from "luxon";
import { useCallback, useEffect, useState } from "react";
import DeleteIcon from "../../icons/DeleteIcon";
import MessageIcon from "../../icons/MessageIcon";
import OrderIcon from "../../icons/OrderIcon";
import NotifyModal from "./NotifyModal";
import SeatModal from "./SeatModal";
import TimeCounter from "./TimeCounter";
import { Howl } from "howler";
import smsNotification from "../../assets/audio/notification.mp3";
import notificationDot from "../../assets/svgs/notificationDot.svg";

interface Params {
  party: DocumentData;
  initialState: any;
  setInitialState: (state: any) => void;
  setActiveParty: (state: DocumentData) => void;
  setOrderOpen: (state: boolean) => void;
  setMessageOpen: (state: boolean) => void;
  setDeleteOpen: (state: boolean) => void;
  print: any;
  printerConnectionStatus: boolean;
}

function CurrentWaitRow(props: Params) {
  const {
    initialState,
    setInitialState,
    party,
    setActiveParty,
    setOrderOpen,
    setMessageOpen,
    setDeleteOpen,
    print,
    printerConnectionStatus
  } = props;

  const [notifyOpen, setNotifyOpen] = useState(false);
  const [seatOpen, setSeatOpen] = useState(false);
  const [wait, setWait] = useState(party?.quotedWait);
  const [showSubmissionNotification, setShowSubmissionNotification] =
    useState(false);

  const sound = new Howl({
    src: [smsNotification],
  });

  useEffect(() => {
    setWait(party?.delaySubmitted ? party.delaySubmitted : party?.quotedWait);
  }, [party]);

  useEffect(() => {
    if(party?.newMessage) {
      sound.play();
    }
  }, [party?.newMessage])
  

  useEffect(() => {
    if (party?.submitted) {
      setShowSubmissionNotification(true);
    } else {
      setShowSubmissionNotification(false);
    }
  }, [party?.submitted]);

  const timeCounter = useCallback(() => {
    if (party?.delaySubmitted) {
      return <TimeCounter quotedTime={party?.delaySubmitted} />;
    } else return <TimeCounter quotedTime={wait} />;
  }, [party, wait]);

  return (
    <>
      <tr
        key={party?.id}
        className={`align-top ${
          party?.customerCanceled
            ? "bg-red-200"
            : party?.customerIsHere
            ? "bg-white"
            : "bg-slate-100"
        }`}
      >
        {/* ORDER */}
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {party?.items?.length ? (
            <button
              onClick={() => {
                setActiveParty(party);
                setOrderOpen(true);
              }}
            >
              <OrderIcon showSubmissionNotification={showSubmissionNotification} />
            </button>
          ) : (
            <OrderIcon disabled={true} />
          )}
        </td>
        {/* NAME and PHONE */}
        <td className="whitespace-nowrap hover:cursor-pointer px-3 py-4 text-sm">
          <div className="flex flex-col">
            <p
              className="font-bold"
              onClick={() => {
                setActiveParty(party);
                setMessageOpen(true);
              }}
            >
              {party?.name}
            </p>
          </div>
        </td>
        {/* PARTY SIZE */}
        <td
          className="whitespace-nowrap px-3 py-4 text-sm"
          onClick={() => {
            setActiveParty(party);
            setMessageOpen(true);
          }}
        >
          {party?.size}
        </td>
        {/* WAIT QUOTE */}
        <td
          className="whitespace-nowrap hover:cursor-pointer px-3 py-4 text-sm"
          onClick={() => {
            setActiveParty(party);
            setMessageOpen(true);
          }}
        >
          {Duration.fromMillis(party?.quotedWait - party?.timeOfQuote).toFormat(
            "hh:mm"
          )}
        </td>
        {/* TIME LEFT */}
        {party?.customerCanceled ? (
          <td className="whitespace-nowrap px-3 py-4">
            <p className="font-bold italic text-md">Cancelled</p>
          </td>
        ) : (
          <td
            className="whitespace-nowrap hover:cursor-pointer px-3 py-4 text-sm"
            onClick={() => {
              setActiveParty(party);
              setMessageOpen(true);
            }}
          >
            {timeCounter()}
          </td>
        )}

        {/* NOTES */}
        <td
          className="whitespace-nowrap hover:cursor-pointer px-3 py-4 text-sm"
          onClick={() => {
            setActiveParty(party);
            setMessageOpen(true);
          }}
        >
          {party?.notes.length > 40
            ? party?.notes.substring(0, 40) + "..."
            : party?.notes}
        </td>
        {/* ACTIONS */}
        <td className="whitespace-nowrap pl-3 pr-6 py-4 text-sm">
          <div className="flex items-center space-x-5 justify-start">
            <button
              className="flex h-full"
              onClick={() => {
                setActiveParty(party);
                setMessageOpen(true);
                setInitialState({ ...initialState, [party.id]: party });
              }}
            >
              <MessageIcon />
              {party?.newMessage ? (
                <img
                  alt="notification dot"
                  src={notificationDot}
                  className="h-5 w-5 relative -top-[8px] -right-[-10px] animate-ping"
                />
              ) : null}
            </button>
            <button
              className="flex"
              onClick={() => {
                setActiveParty(party);
                setDeleteOpen(true);
              }}
            >
              <DeleteIcon />
            </button>
            <div className="flex w-44">
              {party?.status === "notified" ? (
                <button
                  className="px-3 py-2 w-40 bg-blue-600 rounded-md text-white font-bold"
                  onClick={() => {
                    setSeatOpen(true);
                  }}
                >
                  Seat Customer
                </button>
              ) : (
                <button
                  className="px-3 py-2 w-40 bg-black rounded-md text-white font-bold"
                  onClick={() => {
                    setNotifyOpen(true);
                  }}
                >
                  Notify Customer
                </button>
              )}
            </div>
          </div>
        </td>
      </tr>
      <NotifyModal open={notifyOpen} setOpen={setNotifyOpen} party={party} print={print} printerConnectionStatus={printerConnectionStatus} />
      <SeatModal open={seatOpen} setOpen={setSeatOpen} party={party} />
    </>
  );
}

export default CurrentWaitRow;
