const BackArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7.00008V9.00008L4 9.00008L9.5 14.5001L8.08 15.9201L0.16 8.00008L8.08 0.0800781L9.5 1.50008L4 7.00008L16 7.00008Z"
        fill="black"
      />
    </svg>
  );
};

export default BackArrow;
