import { Duration } from "luxon";
import dropdown from "../../assets/svgs/dropdown.svg";

interface Params {
  quotedWait: number;
  setQuotedWait: (state: number) => void;
  style?: string;
  editing?: boolean;
}

export const WaitSelect = (props: Params) => {
  const { quotedWait, setQuotedWait, style, editing } = props;
  // Set the start time and end time in minutes
  const startTime = 30;
  const endTime = 240;

  // Create an array of time options
  const timeOptions: { value: number; timeString: string }[] = [];
  for (let value = startTime; value <= endTime; value += 15) {
    // Convert the time in minutes to a string in the format "hh:mm"
    let milliDur = Duration.fromObject({ minutes: value }).as("millisecond");
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    const timeString = `${
      hours ? (hours === 1 ? "1 hr" : `${hours} hrs`) : ""
    } ${minutes ? `${minutes.toString().padStart(2, "0")} mins` : ""}`;
    // Push the time string to the array of time options
    timeOptions.push({ value: milliDur, timeString });
  }

  return (
    <div className={`flex p-2 h-10 border border-black rounded-md`}>
      <select
        value={quotedWait}
        onChange={(e) => setQuotedWait(parseInt(e.target.value))}
        className={
          style
            ? `${style} appearance-none focus:outline-none z-10 bg-transparent`
            : "w-full appearance-none focus:outline-none z-10 bg-transparent"
        }
      >
        {editing ? (
          <option key={-1} value={-1} className="text-gray-400">
            No change
          </option>
        ) : (
          <option key={-1} value={-1} className="text-gray-400">
            Select
          </option>
        )}
        <option key={0} value={0}>
          No wait
        </option>
        <option key={300000} value={300000}>
          5 mins
        </option>
        <option key={600000} value={600000}>
          10 mins
        </option>
        <option key={900000} value={900000}>
          15 mins
        </option>
        <option key={1200000} value={1200000}>
          20 mins
        </option>
        {timeOptions.map((time) => (
          <option key={time.value} value={time.value}>
            {time.timeString}
          </option>
        ))}
      </select>
      <img
        className={`relative h-3 w-3 ml-[-12px] top-[5px]`}
        src={dropdown}
        alt="arrow down"
      />
    </div>
  );
};
