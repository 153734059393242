interface Params {
  height?: string;
  width?: string;
}

const DeleteIcon = (props: Params) => {
  const { height, width } = props;
  return (
    <svg
      width={width ? width : "30"}
      height={height ? height : "30"}
      viewBox="0 0 28 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4166 1.91667H20.7083L18.7916 0H9.20831L7.29165 1.91667H0.583313V5.75H27.4166M2.49998 30.6667C2.49998 31.6833 2.90385 32.6584 3.62274 33.3772C4.34163 34.0961 5.31665 34.5 6.33331 34.5H21.6666C22.6833 34.5 23.6583 34.0961 24.3772 33.3772C25.0961 32.6584 25.5 31.6833 25.5 30.6667V7.66667H2.49998V30.6667Z"
        fill="black"
      />
    </svg>
  );
};

export default DeleteIcon;
