import { Dialog, Transition } from "@headlessui/react";
import { DocumentData } from "firebase/firestore";
import { Fragment, useCallback, useEffect, useState } from "react";
import PrintConfirmationModal from "./PrintConfirmationModal";

interface Params {
  open: boolean;
  setOpen: (state: boolean) => void;
  party: DocumentData | null;
  print: any;
  printerStatus: boolean;
  connectPrinter: () => void;
}

const OrderModal = (props: Params) => {
  const { open, setOpen, party, print, printerStatus, connectPrinter } = props;

  const [printOpen, setPrintOpen] = useState(false);

  const handleOpenPrint = () => {
    setPrintOpen(true);
    setOpen(false);
  }

  const handleClosePrint = () => {
    setPrintOpen(false);
    setOpen(true);
  }

  const total = useCallback(() => {
    let total = 0;
    party?.items?.forEach((x: any) => {
      total = total + x.price * x.quantity;
    });
    return total.toFixed(2);
  }, [party?.items]);

  const capitalize = ([first, ...rest]: string[], lowerRest = false) =>
    first.toUpperCase() +
    (lowerRest ? rest.join("").toLowerCase() : rest.join(""));

  const closeThis = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("party", party);
  }, []);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-8 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-x-hidden overflow-y-auto rounded-lg bg-white p-10 text-left shadow-xl w-fit transition-all max-h-[70vh]">
                  <div>
                    <div className="flex justify-between mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="flex text-3xl font-bold leading-6 text-secondary text-left mb-8"
                      >
                        {party?.name} Order
                      </Dialog.Title>
                      {party?.submitted ? (
                        <div className="flex w-fit h-fit bg-secondary px-2 py-1 rounded-md text-white font-bold text-xs">
                          Order Submitted
                        </div>
                      ) : null}
                    </div>

                    <div className="flex w-full max-h-56 h-1/3 overflow-y-auto overflow-x-hidden pr-3">
                      <table className="border-separate border-spacing-x-12 border-spacing-y-4 mx-[-3rem] my-[-1rem]">
                        <thead className="top-0 sticky">
                          <tr className="bg-white">
                            <th>Items:</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {party?.items?.map((x: any) => (
                            <>
                              <tr className="align-top" key={x?.name}>
                                <td className="flex flex-col">
                                  {x?.name}{" "}
                                  {x.variant?.name
                                    ? `| ${capitalize(x.variant.name)}`
                                    : ""}
                                  <span className="text-xs text-gray-500">
                                    {x.option
                                      ? `Selected Option(s): ${x.option} ${x.extraOption ? `| ${x.extraOption}` : ""}`
                                      : ""}
                                  </span>
                                </td>
                                <td>{x?.quantity}</td>
                                <td>{x?.price}</td>
                                <td className="font-bold">
                                  {x?.price * x?.quantity}
                                </td>
                              </tr>
                              {x?.modification ? (
                                <div className="flex ml-3 mt-[-10px] text-center text-sm text-slate-600">
                                  - {x.modification}
                                </div>
                              ) : null}
                              {x?.temperature ? (
                                <div className="flex mt-[-10px] text-center text-sm text-slate-600">
                                  Temp:{x.temperature}
                                </div>
                              ) : null}
                              {x.dippingSauce ? (
                                <p className="text-sm text-slate-600 break-words">
                                  Dipping sauce: {x.dippingSauce}
                                </p>
                              ) : null}
                              {x?.side && x?.side?.name ? (
                                <p className="text-sm text-slate-600 break-words">
                                  Side: {x.side.name}{" "}
                                  {x.side.upcharge === 0
                                    ? ""
                                    : `+$${x.side.upcharge}`}
                                </p>
                              ) : null}
                              {x?.extraSide && x?.side?.name ? (
                                <p className="text-sm text-slate-600 break-words">
                                  2nd Side: {x.extraSide.name}{" "}
                                  {x.extraSide.upcharge === 0
                                    ? ""
                                    : `+$${x.extraSide.upcharge}`}
                                </p>
                              ) : null}
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex w-full justify-between border-t py-7 my-7 pr-4 font-bold">
                      <p>Subtotal</p>
                      <p className="flex w-20 justify-start">{"$" + total()}</p>
                    </div>
                    <div>
                      {party?.submitted ? (
                        printerStatus ? (
                          <button
                            className={`px-6 py-2 rounded-md border border-black font-bold`}
                            onClick={() => {
                              handleOpenPrint()
                            }}
                          >
                            {party?.printed ? "Print Again" : "Print Ticket"}
                          </button>
                        ) : (
                          <button
                            className={`px-6 py-2 rounded-md border border-black font-bold`}
                            onClick={() => {
                              console.log("connect printer clicked");
                              connectPrinter();
                            }}
                          >
                            Connect Printer
                          </button>
                        )
                      ) : (
                        <button
                          className={`px-6 py-2 rounded-md border border-black font-bold opacity-30`}
                          disabled={true}
                          onClick={() => {}}
                        >
                          Order in progress
                        </button>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <PrintConfirmationModal
        open={printOpen}
        setOpen={handleOpenPrint}
        setClose={handleClosePrint}
        party={party ? party : {}}
        print={print}
        closeParent={closeThis}
      />
    </>
  );
};

export default OrderModal;
