import { Navigate, useLocation } from "react-router-dom";
import { useSigninCheck } from "reactfire";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {

  const { data: signInCheckResult } = useSigninCheck();
  let location = useLocation();

  if (signInCheckResult?.signedIn === false) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default ProtectedRoute;