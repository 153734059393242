import { DocumentData } from "firebase/firestore";
import { useCallback } from "react";
import PastWaitRow from "./PastWaitRow";

interface Params {
  waitlist: DocumentData[];
  searchText: string;
}

const PastWaitTable = (props: Params) => {
  const { waitlist, searchText } = props;

  const filterWaitlistWithSearchText = useCallback(() => {
    if (searchText?.length > 1) {
      return waitlist?.filter((x) => {
        // Filter by name
        return x.name.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      return waitlist;
    }
  }, [searchText, waitlist]);

  return (
    <div className="rounded-xl shadow-lg border-slate-200 mt-8 border isolate overflow-hidden">
      <div className="overflow-auto max-h-[500px] rounded-xl">
        <table className="min-w-full divide-y divide-slate-300 rounded-xl table-fixed">
          <thead className="bg-white top-0 sticky">
            <tr>
              <th
                scope="col"
                className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600 sm:pl-6"
              >
                Order
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
              >
                Size
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
              >
                Wait Quoted
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
              >
                Seated Time
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600 w-1/4"
              >
                Notes
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-600"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white text-left">
            {filterWaitlistWithSearchText()?.map((x) => (
              <PastWaitRow key={x.id} party={x} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PastWaitTable;
