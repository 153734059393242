import { Dialog, Transition } from "@headlessui/react";
import {
  arrayRemove,
  doc,
  DocumentData,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import BackArrow from "../../icons/BackArrow";
import Message from "./Message";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { useParams } from "react-router-dom";
import TimeCounter from "./TimeCounter";
import { Duration } from "luxon";
import { Item } from "../../types/Item";
import MessageModalItem from "./MessageModalItem";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import DeleteIcon from "../../icons/DeleteIcon";
import SeatModal from "./SeatModal";
import NotifyModal from "./NotifyModal";
import { parsePhoneNumber } from "libphonenumber-js";
import { PencilIcon } from "@heroicons/react/solid";
import EditPartyModal from "./EditPartyModal";

interface Params {
  party?: DocumentData | null;
  restaurantInfo: DocumentData;
  open: boolean;
  setOpen: (state: boolean) => void;
  print: any;
  printerConnectionStatus: boolean;
}

const MessageView = (props: Params) => {
  const { open, setOpen, party, restaurantInfo, print, printerConnectionStatus } = props;
  const [messageText, setMessageText] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [seatOpen, setSeatOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [sendingError, setSendingError] = useState("");

  const functions = useFunctions();
  const { restaurantId } = useParams();
  const db = getFirestore();

  const sendMessageToCustomer = httpsCallable(functions, "textMessageFunctions-sendTextMessage");

  const removeItem = async (item: Item) => {
    const orderInfoRef = doc(
      db,
      `Restaurants/${restaurantId}/Orders/${party?.id}`
    );
    await updateDoc(orderInfoRef, {
      items: arrayRemove(item),
    });
  };

  const handleMessage = (event: any) => {
    setMessageText(event.target.value);
  };

  const handleSendMessage = async (message: string) => {
    // Clearing form
    setMessageText("");
    party?.message.push({
      from: party?.phone,
      message: message,
      sender: "host",
    });
    setSending(true);
    // Sending confirmation text with document data
    await sendMessageToCustomer({
      hostMessage: message,
      phone: party?.phone,
      restaurantId: restaurantId,
      orderId: party?.id,
    }).then((res: any) => {
      if (res?.data?.error) {
        setSendingError(res.data.message);
      }
      setSending(false);
    });

    // for local testing
    // party?.message.push({ message: messageText, sender: "host", time: DateTime.now().toMillis()});
  };

  const closeThis = () => {
    setOpen(false);
  };

  const clearMessageNotification = async () => {
    console.log("clearing message notification");
    let partyRef = doc(db, `Restaurants/${restaurantId}/Orders`, party?.id);

    await updateDoc(partyRef, {
      newMessage: false,
    });
  };

  const subTotalOrder = useCallback(() => {
    let total = 0;
    if (party?.items?.length) {
      party?.items.forEach((item: Item) => {
        if (item.quantity) {
          let itemSubtotal = item.quantity * item.price;
          total += itemSubtotal;
        }
      });
    }
    return total.toFixed(2);
  }, [party?.items]);

  const timeCounter = useCallback(() => {
    if (party?.delaySubmitted) {
      return <TimeCounter quotedTime={party?.delaySubmitted} />;
    } else return <TimeCounter quotedTime={party?.quotedWait} />;
  }, [party]);

  const openEditModal = () => {
    setOpen(false);
    setEditOpen(true);
  }

  const closeEditModal = () => {
    setEditOpen(false);
    setOpen(true);
  }

  // Get the message window to auto-scroll to the bottom
  const messageEndRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });
  


  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full w-[100vw] items-end justify-center p-8 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-3 text-left shadow-xl w-full h-[100vh] transition-all">
                  <div className="flex flex-col w-full h-full py-6 bg-white">
                    {/* Header */}
                    <div className="flex w-full h-10 px-8">
                      <button
                        onClick={() => {
                          clearMessageNotification();
                          setOpen(false);
                        }}
                      >
                        <BackArrow />
                      </button>
                    </div>
                    {/* Body */}
                    <div className="flex h-full w-full">
                      {/* Party Info */}
                      <div className="flex flex-col text-left w-[60%] border-r border-r-slate-300 px-8 overflow-y-auto">
                        <p className="text-3xl font-bold text-secondary my-3">
                          {party?.name}
                        </p>
                        <div className="flex justify-between space-x-3 items-center">
                          {party?.status === "notified" ? (
                            <button
                              className="px-3 py-2 w-full bg-blue-600 rounded-md text-white font-bold"
                              onClick={() => {
                                setSeatOpen(true);
                              }}
                            >
                              Seat Customer
                            </button>
                          ) : (
                            <button
                              className="px-3 py-2 w-full bg-black rounded-md text-white font-bold"
                              onClick={() => {
                                setNotifyOpen(true);
                              }}
                            >
                              Notify Customer
                            </button>
                          )}
                          <button
                            className="p-2 bg-black rounded-full w-9 h-9"
                            onClick={() => {
                              openEditModal();
                            }}
                          >
                            <PencilIcon height={20} width={20} color={'white'} />
                          </button>
                          <button
                            className=""
                            onClick={() => {
                              setDeleteOpen(true);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                        <div className="my-4">
                          {party?.phone ? (
                            <p className="">
                              <span className="font-bold">Phone:</span>{" "}
                              {parsePhoneNumber(
                                "+1" + party?.phone
                              ).formatNational()}
                            </p>
                          ) : null}
                          <p className="">
                            <span className="font-bold">Party Size:</span>{" "}
                            {party?.size}
                          </p>
                          <p className="">
                            <span className="font-bold">Wait Quote:</span>{" "}
                            {party?.quotedWait && party?.timeOfQuote
                              ? Duration.fromMillis(
                                  party?.quotedWait - party?.timeOfQuote
                                ).toFormat("h 'hours and' mm 'minutes'")
                              : null}
                          </p>
                          <div className="flex">
                            <span className="font-bold mr-1">Time Left:</span>{" "}
                            {timeCounter()}
                          </div>
                          <p className="">
                            <span className="font-bold">Table Number:</span>{" "}
                            {party?.tableNumber ?? "Pending"}
                          </p>
                          {/* STATUS TAGS */}
                          <div className="flex">
                            {party?.addedByHost ? (
                              <div className="bg-slate-700 mt-2 px-2 py-1 rounded-md text-white font-bold text-xs">
                                Added by Host
                              </div>
                            ) : null}
                            {party?.customerIsHere ? (
                              <div className="bg-secondary mt-2 mx-1 px-2 py-1 rounded-md text-white font-bold text-xs">
                                Customer is Here
                              </div>
                            ) : null}
                            {party?.delaySubmitted ? (
                              <div className="bg-blue-700 mt-2 mx-1 px-2 py-1 rounded-md text-white font-bold text-xs">
                                Delayed Seating
                              </div>
                            ) : null}
                          </div>
                          {party?.notes ? (
                            <>
                              <p className="font-bold mt-2 mb-1">Notes</p>
                              <p className="max-h-40 overflow-y-auto p-3 border border-slate-200 rounded-md">
                                {party?.notes}
                              </p>
                            </>
                          ) : null}
                        </div>
                        {/* Order Pane */}
                        {party?.items?.length ? (
                          <div className="bg-slate-100 rounded-md p-3 flex flex-col pt-5">
                            <div className="overflow-y-auto max-h-52 pr-2">
                              {party?.items.map((item: Item) => {
                                return (
                                  <MessageModalItem
                                    item={item}
                                    removeItem={removeItem}
                                    key={item.name}
                                  />
                                );
                              })}
                            </div>
                            <div className="flex justify-between py-4 border-t border-black">
                              <p className="font-bold">Subtotal</p>
                              <p className=" font-extrabold">
                                ${subTotalOrder()}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* Message Pane */}
                      <div className="flex flex-col h-full w-full px-8 justify-between">
                        <p className="text-2xl font-bold text-secondary my-3">
                          Messages
                        </p>
                        {/* Message Feed */}
                        <div className="flex flex-col w-full py-2 grow pr-5 overflow-y-auto">
                          {party?.message?.map((m: any) => (
                            <Message
                              key={m.time}
                              message={m}
                              restaurantInfo={restaurantInfo}
                              partyName={party?.name ? party.name : ""}
                            />
                          ))}
                          <div ref={messageEndRef} key={"messageEndRef"} />
                        </div>
                        {/* Message Input */}
                        {sendingError ? (
                          <div className="p-4 rounded-xl bg-red-600 text-white font-bold mb-2">
                            {sendingError}
                          </div>
                        ) : null}
                        <div className="flex justify-end">
                          {sending ? (
                            <p className="flex">
                              Sending{" "}
                              <svg
                                className="animate-spin h-4 w-4 ml-3 mt-0.5"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-0"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </p>
                          ) : null}
                        </div>
                        <div className="flex h-fit justify-between border border-slate-300 rounded-xl w-full p-3 space-x-2 items-center">
                          <form
                            action=""
                            onSubmit={() => {}}
                            className="flex w-full space-x-2"
                          >
                            <input
                              placeholder={"Message"}
                              value={messageText}
                              onChange={handleMessage}
                              className="flex resize-none grow overflow-y-auto w-full flex-wrap bg-transparent focus:outline-none text-left"
                              maxLength={1600}
                            />
                            {messageText.length ? (
                              <button
                                className="flex p-1 border border-blue-500 h-8 rounded-lg items-center capitalize font-bold text-sm text-blue-500"
                                onClick={() => handleSendMessage(messageText)}
                                type="submit"
                              >
                                SEND
                              </button>
                            ) : null}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <DeleteConfirmationModal
        party={party ? party : {}}
        open={deleteOpen}
        setOpen={setDeleteOpen}
        closeParent={closeThis}
      />
      <EditPartyModal
        party={party ? party : {}}
        open={editOpen}
        setClose={closeEditModal}
      />
      <NotifyModal
        open={notifyOpen}
        setOpen={setNotifyOpen}
        party={party ? party : {}}
        print={print}
        printerConnectionStatus={printerConnectionStatus}
      />
      <SeatModal
        open={seatOpen}
        setOpen={setSeatOpen}
        party={party ? party : {}}
        closeParent={closeThis}
      />
    </>
  );
};

export default MessageView;
