interface Params {
    height?: string;
    width?: string;
}

const MessageIcon = (props: Params) => {
  const { height, width } = props;
  return (
    <svg
      width={width ? width : "30"}
      height={height ? height : "30"}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 0C27.7956 0 28.5587 0.31607 29.1213 0.87868C29.6839 1.44129 30 2.20435 30 3V21C30 21.7956 29.6839 22.5587 29.1213 23.1213C28.5587 23.6839 27.7956 24 27 24H6L0 30V3C0 1.335 1.35 0 3 0H27ZM3 3V22.755L4.755 21H27V3H3ZM6 7.5H24V10.5H6V7.5ZM6 13.5H19.5V16.5H6V13.5Z"
        fill="black"
      />
    </svg>
  );
};

export default MessageIcon;
