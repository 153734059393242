import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import QuantityInput from "./shared/QuantityInput";
import { WaitSelect } from "./shared/WaitSelect";
import { useParams } from "react-router-dom";
import { addDoc, collection, getDoc, getFirestore } from "firebase/firestore";
import { DateTime } from "luxon";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { toast } from "react-toastify";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";

interface Params {
  open: boolean;
  setOpen: (state: boolean) => void;
}

const AddWaitlistModal = (props: Params) => {
  const { open, setOpen } = props;
  const [name, setName] = useState("");
  const [quantity, setQuanity] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [waitQuote, setWaitQuote] = useState<number>(-1);
  const [notes, setNotes] = useState("");
  const [hereCheck, setHereCheck] = useState(true);
  const [formValid, setFormValid] = useState(false);

  const db = getFirestore();
  const { restaurantId } = useParams();
  const functions = useFunctions();

  const sendQuotedTimeTextMessage = httpsCallable(
    functions,
    "textMessageFunctions-sendQuotedTimeTextMessage"
  );

  const createParty = async () => {
    // Creating the document in Firestore
    await addDoc(collection(db, `Restaurants/${restaurantId}/Orders`), {
      name: name,
      size: quantity,
      // removing formatting characters from the phone number
      phone: phoneNumber.replace(/[-+()\s]/g, ''),
      notes: notes,
      timeOfQuote: DateTime.local().toMillis(),
      quotedWait: DateTime.local().toMillis() + waitQuote,
      status: "waiting",
      customerIsHere: hereCheck,
      items: [],
      message: [],
      addedByHost: true,
      updatedAt: DateTime.local().toMillis()
    }).then(async (docRef) => {
      // Getting the document after creation
      const docSnap = await getDoc(docRef);

      // Checking to see if document exists
      if (docSnap.exists()) {
        // Clearing the states for the form
        clearForm();
        let data = docSnap.data();
        toast.success(
          <div className="-ml-3">
            <span className=" font-bold text-black">{data?.name} </span>
            <span>added to the list</span>
          </div>,
          {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            closeButton: false,
            draggable: true,
            progress: undefined,
            toastId: "item-added",
            className:
              "!border-l-8 !border-l-eatout-notification-green !rounded-l-lg mt-6",
            bodyClassName: "",
            icon: (
              <svg
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z"
                  fill="#45AF2D"
                />
              </svg>
            ),
          }
        );

        // Sending confirmation text with document data
        await sendQuotedTimeTextMessage({
          orderId: docRef?.id,
          name: data?.name,
          restaurantId: restaurantId,
          quotedWait: waitQuote,
          phone: data?.phone,
          timeOfQuote: DateTime.local().toMillis(),
          addedByHost: data?.addedByHost,
        });
      }
    });
  };

  const clearForm = () => {
    setName("");
    setQuanity(0);
    setPhoneNumber("");
    setWaitQuote(0);
    setNotes("");
    setHereCheck(true);
  };

  const handleChange = (event: any, type: string) => {
    switch (type) {
      case "name":
        setName(event.target.value);
        break;
      case "phoneNumber":
        // formatting the phone number
        if (/(.?\d){4,}/.test(event.target.value)) {
          setPhoneNumber(new AsYouType("US").input(event.target.value));
        } else setPhoneNumber(event.target.value);
        break;
      case "notes":
        setNotes(event.target.value);
        break;
    }
  };

  const handleCheckbox = (event: any, type: string) => {
    switch (type) {
      case "here-check":
        const checked = event?.target?.checked;
        setHereCheck(checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      name &&
      quantity > 0 &&
      isValidPhoneNumber(phoneNumber, "US") &&
      waitQuote >= 0
    ) {
      setFormValid(true);
    } else setFormValid(false);
  }, [name, phoneNumber, quantity, waitQuote]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-bold leading-6 text-gray-900 text-left mb-6"
                    >
                      Add to Waitlist
                    </Dialog.Title>
                    <div className="mt-2 grid grid-cols-2 gap-6 mb-7">
                      <div className="flex flex-col text-left space-y-2">
                        <label htmlFor="name" className="font-bold">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="p-2 h-10 border border-black rounded-md w-60"
                          value={name}
                          onChange={(e) => handleChange(e, "name")}
                          placeholder="Enter name"
                        />
                      </div>
                      <div className="flex flex-col text-left space-y-2">
                        <label htmlFor="phoneNumber" className="font-bold">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          id="phoneNumber"
                          className="p-2 h-10 border border-black rounded-md w-60"
                          value={phoneNumber}
                          onChange={(e) => handleChange(e, "phoneNumber")}
                          placeholder="555-555-5555"
                        />
                      </div>
                      <div className="flex flex-col text-left space-y-2">
                        <label htmlFor="" className="font-bold ">
                          Wait Time
                        </label>
                        <WaitSelect
                          quotedWait={waitQuote}
                          setQuotedWait={setWaitQuote}
                        />
                      </div>
                      <div className="flex flex-col text-left space-y-2">
                        <div className="flex h-6">
                          <label htmlFor=""></label>
                        </div>
                        <div className="flex items-center h-full space-x-2">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-6 w-6 text-primary focus:ring-primary border-gray-300 rounded"
                            checked={hereCheck}
                            onChange={(e) => {
                              handleCheckbox(e, "here-check");
                            }}
                          />
                          <label htmlFor="" className="font-bold">
                            Are they here?
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col text-left mb-10">
                      <p className="font-bold">How many people in the party?</p>
                      <QuantityInput
                        quantity={quantity}
                        setQuantity={setQuanity}
                      />
                    </div>
                    <div className="flex flex-col text-left space-y-2">
                      <label htmlFor="notes" className="font-bold">
                        Anything we should know about?
                      </label>
                      <textarea
                        className="w-full bg-slate-100 rounded-md resize-none pt-2 px-3 h-28"
                        placeholder=""
                        onChange={(e) => handleChange(e, "notes")}
                        name=""
                        id="notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    disabled={!formValid}
                    className={`inline-flex w-full justify-center rounded-2xl h-11 items-center font-bold border border-transparent ${
                      formValid ? "bg-secondary" : "bg-slate-300"
                    }  px-4 py-2 text-lg text-white shadow-sm active:brightness-75`}
                    onClick={() => {
                      createParty();
                      setOpen(false);
                    }}
                  >
                    Add to Waitlist
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddWaitlistModal;
