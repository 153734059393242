import { useEffect, useState } from "react";

export default function MessageModalItem({
  item,
}: {
  item: any;
  removeItem: Function;
}) {
  const [currentQuantity, setCurrentQuantity] = useState(
    item?.quantity ? item.quantity : 1
  );

  const capitalize = ([first, ...rest]: string[], lowerRest = false) =>
    first.toUpperCase() +
    (lowerRest ? rest.join("").toLowerCase() : rest.join(""));

  useEffect(() => {
    setCurrentQuantity(item.quantity ? item.quantity : 1);
  }, [item]);

  return (
    <div key={item.id} className={"flex flex-col py-2 px-2"}>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <p className="font-bold text-sm">
            {item.name}{" "}
            {item.variant?.name ? `| ${capitalize(item.variant.name)}` : ""}
          </p>
          <span className="text-xs text-gray-500">
            {item.option ? `Selected Option: ${item.option} ${item.extraOption ? `| ${item.extraOption}` : ""}` : ""}
          </span>
          {item?.temperature ? (
            <p className="text-sm text-slate-600 break-words">
              Temp: {item.temperature}
            </p>
          ) : null}
          {item?.modification ? (
            <p className="text-sm text-slate-600">- {item.modification}</p>
          ) : null}
          {item.dippingSauce ? (
            <p className="text-sm text-slate-600 break-words">
              Dipping sauce: {item.dippingSauce}
            </p>
          ) : null}
          {item?.side && item?.side?.name ? (
            <p className="text-sm text-slate-600 break-words">
              Side: {item.side.name}{" "}
              {item.side.upcharge === 0 ? "" : `+$${item.side.upcharge}`}
            </p>
          ) : null}
          {item?.extraSide && item?.extraSide?.name ? (
            <p className="text-sm text-slate-600 break-words">
              2nd Side: {item.extraSide.name}{" "}
              {item.extraSide.upcharge === 0
                ? ""
                : `+$${item.extraSide.upcharge}`}
            </p>
          ) : null}
        </div>
        <div className="flex flex-col">
          <div className="flex">
            <span className="font-bold">x {currentQuantity}</span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-6">
        <p className="text-sm">${item.price}</p>
      </div>
    </div>
  );
}
