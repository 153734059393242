import { DocumentData } from "firebase/firestore";
import { useEffect } from "react";
import { MessageType } from "../../types/MessageType";

interface Params {
  message: MessageType;
  restaurantInfo: DocumentData;
  partyName: string;
}

const getInitials = (name: string) => {
  let firstAndLast = name.split(" ");
  let initials = "";
  firstAndLast.forEach((x) => (initials = initials + x.charAt(0)));
  return initials;
};

const Message = (props: Params) => {
  const { message, restaurantInfo, partyName } = props;

  useEffect(() => {
  }, [message]);

  return (
    <div
      className={`flex space-x-4 mb-4 ${
        message.sender === "host" ? "flex-row-reverse space-x-reverse" : null
      }`}
    >
      <div
        className={`flex ${
          message.sender === "host" ? "bg-secondary" : "bg-slate-400"
        } h-11 w-11 rounded-full items-center justify-center text-white font-bold`}
      >
        {message.sender === "host"
          ? getInitials(restaurantInfo?.name)
          : getInitials(partyName)}
      </div>
      <p
        className={`p-3 rounded-lg ${
          message.sender === "host" ? "bg-[#FFD68D]" : "bg-slate-200"
        } max-w-md text-left break-words`}
      >
        {message.message}
      </p>
    </div>
  );
};

export default Message;
