import { DocumentData, getFirestore } from "firebase/firestore";
import { useState } from "react";
import { WaitSelect } from "../shared/WaitSelect";
import { doc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { DateTime } from "luxon";
import DeclineWaitlistModal from "./DeclineWaitlistModal";
import TranspiredCounter from "./TranspiredCounter";

interface Params {
  request: DocumentData;
  setNotesOpen: (state: boolean) => void;
  setActiveNotes: (state: string) => void;
}

export const RequestRow = (props: Params) => {
  const { request, setNotesOpen, setActiveNotes } = props;

  const [quotedWait, setQuotedWait] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const { restaurantId } = useParams();
  const db = getFirestore();
  const functions = useFunctions();
  const requestRef = doc(db, `Restaurants/${restaurantId}/Orders`, request?.id);

  const sendQuotedTimeTextMessage = httpsCallable(
    functions,
    "textMessageFunctions-sendQuotedTimeTextMessage"
  );

  const sendWait = async () => {
    await updateDoc(requestRef, {
      quotedWait: DateTime.local().toMillis() + quotedWait,
      status: "unconfirmed",
    });

    // call this when submitting the time
    await sendQuotedTimeTextMessage({
      orderId: request.id, //grab from order object,
      name: request.name,
      restaurantId: restaurantId, //grab from URL param,
      quotedWait: quotedWait,
      phone: request.phone, // grab from order object
      timeOfQuote: DateTime.local().toMillis(),
      addedByHost: request.addedByHost,
    });
  };

  return (
    <>
      <tr key={request?.id} className="align-top border-b-8 border-b-blue-400">
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <div className="flex flex-col">
            <p className="font-bold">{request?.name}</p>
            <p>{request?.phone}</p>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">{request?.size}</td>
        <td
          className="whitespace-nowrap px-3 py-4 text-sm"
          onClick={() => {
            if (request?.notes) {
              setActiveNotes(request?.notes);
              setNotesOpen(true);
            }
          }}
        >
          {request?.notes.length > 40
            ? request?.notes.substring(0, 40) + "..."
            : request?.notes}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm"><TranspiredCounter quotedTime={request?.timeOfQuote}/></td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          <div className="flex space-x-3">
            <WaitSelect quotedWait={quotedWait} setQuotedWait={setQuotedWait} />
            <button
              className={`px-3 py-2 ${
                quotedWait ? "bg-primary" : "bg-slate-300 disabled"
              }  rounded-md text-white font-bold`}
              disabled={!quotedWait}
              onClick={() => {
                sendWait();
              }}
            >
              Send Wait Time
            </button>
            <button
              className="px-3 py-2 w-32 bg-black rounded-md text-white font-bold"
              onClick={() => setOpenModal(true)}
            >
              Decline
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=" inline float-right mt-0.5"
              >
                <path
                  d="M16 2.91L13.09 0L8 5.09L2.91 0L0 2.91L5.09 8L0 13.09L2.91 16L8 10.91L13.09 16L16 13.09L10.91 8L16 2.91Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </td>
      </tr>
      <DeclineWaitlistModal
        open={openModal}
        setOpen={setOpenModal}
        party={request}
      />
    </>
  );
};
