import "./App.css";
import Home from "./components/Home";
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
} from "reactfire";

// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom";
import { getAuth } from "firebase/auth";
import ProtectedRoute from "./components/ProtectedRoute";
import PageNotFound from "./components/PageNotFound";
import Login from "./components/Login/Login";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function App() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const functions = getFunctions(useFirebaseApp());
  const auth = getAuth(useFirebaseApp());

  const router = createBrowserRouter([
    {
      path: ":restaurantId",
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
    },
    {
      path: "*",
      element: <PageNotFound />,
      loader: async () => {
        await auth.onAuthStateChanged(function(user) {
          if (user) {
            console.log("🚀 ~ file: App.tsx:34 ~ auth.onAuthStateChanged ~ user", user)
            // User is signed in.
            return redirect('/bigwhiskeys')
          } else {
            // No user is signed in.
            return redirect('/')
          }
        });

        return null;
      },
    },
    { path: "/", element: <Login /> },
  ]);

  // connectFunctionsEmulator(functions, "localhost", 5001);

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <FunctionsProvider sdk={functions}>
        <AuthProvider sdk={auth}>
          <RouterProvider router={router} />
        </AuthProvider>
      </FunctionsProvider>
    </FirestoreProvider>
  );
}

export default App;
