import { useEffect, useRef, useState } from "react";
import AddWaitlistModal from "./AddWaitlistModal";
import CurrentWaitTable from "./CurrentParties/CurrentWaitTable";
import Header from "./Header";
import PastWaitTable from "./PastParties/PastWaitTable";
import RequestsTable from "./Requests/RequestsTable";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useFirestoreCollectionData, useFirestoreDocData } from "reactfire";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DateTime } from "luxon";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Current", current: true },
  { name: "Past", current: false },
];

const Home = () => {
  const [currentTab, setCurrentTab] = useState("Current");
  const [addWaitOpen, setAddWaitOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [initialState, setInitialState] = useState({});
  const [printerConnectionStatus, setPrinterConnectionStatus] = useState(false);
  const [printerConnecting, setPrinterConnecting] = useState(false);

  const { restaurantId } = useParams();

  const ePosDevice = useRef();
  const printer = useRef();

  const db = getFirestore();
  const ordersRef = collection(db, `Restaurants/${restaurantId}/Orders`);
  const pastOrdersRef = collection(
    db,
    `Restaurants/${restaurantId}/PastOrders`
  );

  const requestsQuery = query(ordersRef, where("status", "==", "requested"));
  const { data: requests } = useFirestoreCollectionData(requestsQuery, {
    idField: "id",
  });

  const restaurantInfoRef = doc(db, `Restaurants/${restaurantId}`);
  const { data: restaurantInfoDetail } = useFirestoreDocData(
    restaurantInfoRef,
    {
      idField: "id",
    }
  );

  const activePartiesQuery = query(
    ordersRef,
    where("status", "in", ["waiting", "notified"])
  );
  const { status: activePartyStatus, data: activeParties } =
    useFirestoreCollectionData(activePartiesQuery, {
      idField: "id",
    });

  // Trying to limit the Past Orders table reach
  // const dayAgo = DateTime.local().minus({ days: 1 }).toMillis();

  const expiredPartiesQuery = query(pastOrdersRef);
  const { status: expiredPartyStatus, data: expiredParties } =
    useFirestoreCollectionData(expiredPartiesQuery, {
      idField: "id",
    });

  useEffect(() => {
    setSearchText("");
  }, [currentTab]);

  const createObjectFromArray = (array: any[]) => {
    const obj: any = {};
    array.forEach((order) => {
      obj[order.id] = order;
    });
    return obj;
  };

  const connect = () => {
    console.log("connecting to printer", restaurantInfoDetail);

    let ePosDev = new (window as any).epson.ePOSDevice();
    ePosDevice.current = ePosDev;
    console.log(
      "🚀 ~ file: ThermalPrinter.tsx:30 ~ connect ~ ePosDevice.current",
      ePosDevice?.current,
      restaurantInfoDetail?.printer_ip_address
    );
    setPrinterConnecting(true);
    ePosDev.connect(
      restaurantInfoDetail.printer_ip_address,
      "9100",
      (data: any) => {
        if (data === "SSL_CONNECT_OK") {
          ePosDev.createDevice(
            "eatout_printer",
            ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: true, buffer: false },
            (devobj: any, retcode: any) => {
              if (retcode === "OK") {
                printer.current = devobj;
                console.log(
                  "🚀 ~ file: ThermalPrinter.tsx:41 ~ ePosDev.connect ~ printer",
                  printer
                );
                setPrinterConnectionStatus(true);
                setPrinterConnecting(false);
              } else {
                setPrinterConnecting(false);
                throw retcode;
              }
            }
          );
        } else {
          console.log("data", data);
          setPrinterConnecting(false);
          throw data;
        }
      }
    );
  };

  const print = (partyInfo: any, tableNumber?: string) => {
    console.log("🚀 ~ file: ThermalPrinter.tsx:56 ~ print ~ text", partyInfo);
    let prn: any = printer.current;
    if (!prn) {
      alert("Not connected to printer");
      return;
    }
    if (partyInfo.tableNumber || tableNumber) {
      prn.addText(`Table Number: ${tableNumber ?? partyInfo.tableNumber}\n`);
    }
    prn.addText(`Name: ${partyInfo.name}\n`);
    prn.addText(`Party Size: ${partyInfo.size}\n`);
    if (partyInfo.notes) {
      prn.addText(`${partyInfo.notes}\n`);
      prn.addFeedLine(1);
    }
    prn.addFeedLine(1);
    partyInfo.items.forEach((item: any) => {
      prn.addTextStyle(false, false, true, prn.COLOR_1);
      prn.addText(
        `x${item.quantity} ${item.name}${
          item?.variant?.name ? "\n   " + item.variant.name : ""
        }\n`
      );
      prn.addTextStyle(false, false, false, prn.COLOR_1);
      if (item?.modification) {
        prn.addText(`-- ${item.modification}`);
      }
      if (item?.option || item?.extraOption) {
        const options = [item.option, item.extraOption].filter(Boolean);
        prn.addText(`-- Option: ${options.join(' | ')}`);
      }
      if (item?.temperature) {
        prn.addText(`-- Temp: ${item.temperature}`);
      }
      if (item?.dippingSauce) {
        prn.addText(`-- Dipping Sauce ${item.dippingSauce}`);
      }
      if (item?.side?.name) {
        prn.addText(
          `-- Side: ${item.side.name} ${
            item.side.upcharge === 0 ? "" : `+$${item.side.upcharge}`
          }`
        );
      }
      if (item?.extraSide?.name) {
        prn.addText(
          `-- 2nd Side: ${item.extraSide.name} ${
            item.extraSide.upcharge === 0 ? "" : `+$${item.extraSide.upcharge}`
          }`
        );
      }
      prn.addFeedLine(2);
    });
    prn.addCut(prn.CUT_FEED);

    prn.send();
  };

  useEffect(() => {
    if (!printer.current && restaurantInfoDetail?.printer_ip_address) {
      connect();
    }
  }, [restaurantInfoDetail]);

  // Setting the initial state of the waitlist
  // this is used to handle message notifications
  useEffect(() => {
    if (activePartyStatus === "success") {
      // console.log("SETTING INITIAL STATE TO: ", createObjectFromArray(activeParties));
      setInitialState(createObjectFromArray(activeParties));
    }
  }, [activePartyStatus]);

  // setup an interval to check the printer connection status every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (!printerConnectionStatus && !printerConnecting) {
        console.log("checking printer connection status");
        setPrinterConnectionStatus(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let interval: any;

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Page is visible, check the printer connection status
        if (!printerConnectionStatus && !printerConnecting) {
          console.log("checking printer connection status");
          connect();
        }
      }
    };

    // Set the name of the hidden property and the change event for visibility
    let hidden, visibilityChange: any; 
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    // @ts-ignore
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    // @ts-ignore
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    // Handle page visibility change
    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    // Clean up function
    return () => {
      clearInterval(interval);
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, [restaurantInfoDetail]);

  return (
    <div className="App min-h-screen bg-white">
      {/* Header */}
      <Header
        restaurantInfo={restaurantInfoDetail}
        setAddWaitOpen={setAddWaitOpen}
        setSearchText={setSearchText}
        searchText={searchText}
        printerConnectionStatus={printerConnectionStatus}
        printerConnecting={printerConnecting}
      />
      <ToastContainer limit={2} />
      {/* Body */}
      <div className="flex flex-col w-full p-10">
        {/* Waitlist Requests */}
        {requests?.length ? (
          <RequestsTable
            requests={requests.sort((a, b) => a.timeOfQuote - b.timeOfQuote)}
          />
        ) : null}
        <div className="flex flex-col w-full">
          {/* Tabs */}
          <div className="mt-6 sm:mt-2 2xl:mt-5">
            <div className="border-b border-gray-200">
              <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => {
                        setCurrentTab(tab.name);
                      }}
                      className={classNames(
                        tab.name === currentTab
                          ? "border-secondary rounded-t-sm text-gray-900"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name +
                        (tab.name === "Current"
                          ? ` (${activeParties?.length})`
                          : ` (${
                              expiredParties?.filter(
                                (party: any) =>
                                  party.timeOfQuote >
                                  DateTime.now().minus({ days: 1 }).toMillis()
                              )?.length
                            })`)}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {currentTab === "Current" ? (
            activeParties ? (
              <CurrentWaitTable
                initialState={initialState}
                printerStatus={printerConnectionStatus}
                connectPrinter={connect}
                setInitialState={setInitialState}
                waitlist={activeParties?.sort(
                  (a, b) =>
                    a?.timeOfQuote +
                    a?.quotedWait -
                    (b?.timeOfQuote + b?.quotedWait)
                )}
                restaurantInfo={restaurantInfoDetail}
                searchText={searchText}
                print={print}
              />
            ) : (
              <div className="flex flex-col w-full h-full grow pt-[15%] py-11 justify-center items-center">
                <svg
                  className="animate-spin h-12 w-12 ml-3 mt-0.5"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-0"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            )
          ) : null}
          {currentTab === "Past" ? (
            <PastWaitTable
              waitlist={expiredParties
                ?.sort((a, b) => b.timeOfQuote - a.timeOfQuote)
                .filter(
                  (party: any) =>
                    party.timeOfQuote >
                    DateTime.now().minus({ days: 1 }).toMillis()
                )}
              searchText={searchText}
            />
          ) : null}
        </div>
      </div>
      <AddWaitlistModal open={addWaitOpen} setOpen={setAddWaitOpen} />
    </div>
  );
};

export default Home;
