import { Dialog, Transition } from "@headlessui/react";
import {
  deleteDoc,
  doc,
  DocumentData,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Params {
  open: boolean;
  setOpen: (status: boolean) => void;
  party: DocumentData;
  closeParent?: () => void;
}

function SeatModal(props: Params) {
  const { open, setOpen, party, closeParent } = props;

  const { restaurantId } = useParams();
  const db = getFirestore();

  // updates the Party's status to 'notified' and sends a text message notifying them that their table is ready
  const seatParty = async () => {
    // Getting object references
    let pastOrderRef = doc(
      db,
      `Restaurants/${restaurantId}/PastOrders`,
      party?.id
    );
    let currentOrderRef = doc(
      db,
      `Restaurants/${restaurantId}/Orders`,
      party?.id
    );

    // Updating the party status, then moving the document to the PastOrders table
    await setDoc(pastOrderRef, party).then(async () => {
      await deleteDoc(currentOrderRef).then(async () => {
        await updateDoc(pastOrderRef, {
          status: "seated",
          seatedTime: DateTime.local().toMillis(),
        }).then(async () => {
          toast.success(
            <div className="-ml-3">
              <span className=" font-bold text-black">{party?.name} </span>
              <span>has been seated!</span>
            </div>,
            {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              closeButton: false,
              draggable: true,
              progress: undefined,
              toastId: "item-added",
              className:
                "!border-l-8 !border-l-eatout-notification-green !rounded-l-lg mt-6",
              bodyClassName: "",
              icon: (
                <svg
                  width="20"
                  height="17"
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z"
                    fill="#45AF2D"
                  />
                </svg>
              ),
            }
          );
        });
      });
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-8 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-10 text-left shadow-xl w-fit transition-all max-h-[70vh]">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-bold leading-6 text-secondary text-left mb-8"
                    >
                      Seat Customer
                    </Dialog.Title>
                  </div>
                  <div className="flex w-full max-h-56 overflow-y-auto overflow-x-hidden pr-3">
                    <p>
                      Would you like to seat{" "}
                      <span className="font-bold">{party?.name}</span>?
                    </p>
                  </div>
                  <div className="flex mt-9 space-x-9">
                    <button
                      className="py-2 rounded-md"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-6 py-2 rounded-md bg-secondary text-white font-bold"
                      onClick={() => {
                        seatParty();
                        setOpen(false);
                        if (closeParent) {
                          closeParent();
                        }
                      }}
                    >
                      Yes, remove from waitlist
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SeatModal;
