import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/InputField";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const Login = () => {
  const [email, setEmail] = useState("");
  const [rememberMeCheck, setRememberMeCheck] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [toggleShowPassword, setToggleShowPassword] = useState(false);

  const auth = getAuth();
  const submitLogin = async (loginEmail: string, password: string) => {
    await signInWithEmailAndPassword(auth, loginEmail, password)
      .then((userCredential) => {
        if (rememberMeCheck) {
          localStorage.setItem("eatout-remember-me-email", email);
        } else {
          localStorage.setItem("eatout-remember-me-email", "");
        }

        // set persistence to session
        setPersistence(auth, browserSessionPersistence)
          .then(() => {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(
              `Error code: ${errorCode}, Error Message: ${errorMessage}`
            );
          });

        navigate("/bigwhiskeys");
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log("there was an error", errorCode);

        switch (errorCode) {
          case "auth/user-not-found":
            setLoginError("Login Error: User not found or password is invalid");
            break;
          case "auth/wrong-password":
            setLoginError("Login Error: User not found or password is invalid");
            break;
          default:
            setLoginError(null);
            break;
        }
      });
  };

  const validationSchema = Yup.object({
    loginEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    password: Yup.string().min(2).required("Required"),
  });

  const handleCheckbox = (event: any, type: string) => {
    switch (type) {
      case "remember-me":
        const checked = event?.target?.checked;
        setRememberMeCheck(checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (window?.localStorage) {
      const rememberMeLocalStorageValue = localStorage.getItem(
        "eatout-remember-me-email"
      );
      if (rememberMeLocalStorageValue?.length) {
        setRememberMeCheck(true);
        setEmail(rememberMeLocalStorageValue);
      } else {
        setRememberMeCheck(false);
      }
    }
  }, []);

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              EatOut Restaurant Portal
            </h2>
          </div>
          <Formik
            initialValues={{
              loginEmail: email,
              password: "",
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={({ loginEmail, password }) => {
              submitLogin(loginEmail, password);
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              values,
              handleChange,
              isValid,
              submitCount,
            }) => (
              <Form className="mt-8 space-y-6">
                {/* {loginError === null ? null : (
                  <div className="rounded-md bg-red-600 py-2 text-white px-4">
                    {loginError ? <div>{loginError}</div> : null}
                  </div>
                )} */}
                {(isValid || submitCount < 1) && loginError === null ? null : (
                  <div className="rounded-md bg-red-600 py-2 text-white px-4">
                    {loginError ? <div>{loginError}</div> : null}
                    {errors.loginEmail && touched.loginEmail ? (
                      <div>{`Email Address: ${errors.loginEmail}`}</div>
                    ) : null}
                    {errors.password && touched.password ? (
                      <div>{`Password: ${errors.password}`}</div>
                    ) : null}
                  </div>
                )}
                <input type="hidden" name="remember" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <InputField
                      label="Email address"
                      labelClassName="sr-only"
                      htmlFor="email-address"
                      type={"email"}
                      placeholder={"Email address"}
                      name={"loginEmail"}
                      id={"loginEmail"}
                      valid={
                        touched.loginEmail && errors.loginEmail ? false : true
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.loginEmail}
                      validColor={
                        "focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                      }
                      className={
                        "appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-t-md  focus:z-10 sm:text-sm"
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      label="Password"
                      labelClassName="sr-only"
                      htmlFor="password"
                      type={"password"}
                      placeholder={"Password"}
                      name={"password"}
                      id={"password"}
                      valid={touched.password && errors.password ? false : true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      validColor={
                        "focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300"
                      }
                      className={
                        "appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:z-10 sm:text-sm"
                      }
                      showPassword={toggleShowPassword}
                      toggleShowPassword={setToggleShowPassword}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                      checked={rememberMeCheck}
                      onChange={(e) => {
                        handleCheckbox(e, "remember-me");
                      }}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Sign in
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default Login;
