import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FirebaseAppProvider } from "reactfire";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPpyaA6QmwEUONsCFnosKk3AiyJ81pfss",
  authDomain: "eatout-6f1a8.firebaseapp.com",
  projectId: "eatout-6f1a8",
  storageBucket: "eatout-6f1a8.appspot.com",
  messagingSenderId: "892342425664",
  appId: "1:892342425664:web:1d3069ae493131878be7a5",
  measurementId: "G-VSC390104H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
export { db };

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
