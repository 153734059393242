import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, DocumentData, getFirestore, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";

interface Params {
  open: boolean;
  setOpen: (state: boolean) => void;
  party: DocumentData | null;
}

const DeclineWaitlistModal = (props: Params) => {
  const { open, setOpen, party } = props;
  const [messageText, setMessageText] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const { restaurantId } = useParams();
  const functions = useFunctions();
  const db = getFirestore();

  const sendMessageToCustomer = httpsCallable(functions, "textMessageFunctions-sendTextMessage");

  const orderInfoRef = doc(
    db,
    `Restaurants/${restaurantId}/Orders/${party?.id}`
  );

  const handleClose = () => {
    setOpen(false);
    setMessageText("");
  };

  const handleSendMessage = async () => {
    setShowSpinner(true);
    // Sending confirmation text with document data
    await sendMessageToCustomer({
      hostMessage: messageText,
      phone: party?.phone,
      restaurantId: restaurantId,
      orderId: party?.id,
      declined: true,
      name: party?.name
    });

    // call function to change the status of the order
    await updateDoc(orderInfoRef, {
      status: "deleted",
      reasonForDecline: messageText
    });
    setShowSpinner(false);
    setMessageText("");
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => handleClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-10 text-left shadow-xl transition-all">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-bold leading-6 text-gray-900 text-left mb-6"
                    >
                      Decline Add to Waitlist
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-8">
                  <p className=" font-bold">
                    Please tell the customer why they cannot be added to the
                    waitlist.
                  </p>
                  <textarea
                    className="w-full h-40 mt-4 bg-[#F2F2F2] border border-gray-300 rounded-lg p-2 resize-none"
                    placeholder="Party size is too large..."
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                  <div className="flex mt-4 justify-between w-4/5">
                    <button
                      onClick={() => handleClose()}
                      className="outline-none"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`inline-flex w-3/4 justify-center rounded-2xl h-11 bg-secondary items-center font-bold border border-transparent px-4 py-2 text-lg text-white shadow-sm active:brightness-75 disabled:bg-gray-500`}
                      disabled={showSpinner || messageText === ""}
                      onClick={() => handleSendMessage()}
                    >
                      {showSpinner ? (
                        <>
                          Sending Message
                          <svg
                            className="animate-spin h-5 w-5 ml-3 mt-0.5"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </>
                      ) : (
                        <>Send Message</>
                      )}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeclineWaitlistModal;
