import {
  deleteDoc,
  doc,
  DocumentData,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { DateTime, Duration } from "luxon";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import OrderIcon from "../../icons/OrderIcon";

interface Params {
  party: DocumentData;
}

const PastWaitRow = (props: Params) => {
  const { party } = props;

  const { restaurantId } = useParams();
  const db = getFirestore();

  const returnToWaitlist = async () => {
    let pastOrderRef = doc(
      db,
      `Restaurants/${restaurantId}/PastOrders`,
      party?.id
    );
    let currentOrderRef = doc(
      db,
      `Restaurants/${restaurantId}/Orders`,
      party?.id
    );

    await setDoc(currentOrderRef, party).then(async () => {
      await deleteDoc(pastOrderRef).then(async () => {
        updateDoc(currentOrderRef, {
          status: "waiting",
        }).then(() => {
          toast.success(
            <div className="text-left pl-2 -ml-3">
              <span className=" font-bold text-black">{party?.name} </span>
              successfully returned to waitlist!
            </div>,
            {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              closeButton: false,
              draggable: true,
              progress: undefined,
              toastId: "item-added",
              className:
                "!border-l-8 !border-l-eatout-notification-green !rounded-l-lg mt-6",
              bodyClassName: "",
              icon: (
                <svg
                  width="20"
                  height="17"
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z"
                    fill="#45AF2D"
                  />
                </svg>
              ),
            }
          );
        });
      });
    });
  };

  return (
    <tr key={party?.id} className="bg-slate-300">
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {party?.items ? (
          <button>
            <OrderIcon />
          </button>
        ) : (
          <OrderIcon disabled={true} />
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm flex flex-col">
        <p>{party?.name}</p>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">{party?.size}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        {party?.quotedWait
          ? Duration.fromMillis(
              party?.quotedWait - party?.timeOfQuote
            ).toFormat("hh:mm")
          : "N/A"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        {party?.seatedTime
          ? DateTime.fromMillis(party?.seatedTime).toLocaleString(
              DateTime.TIME_SIMPLE
            )
          : null}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        {" "}
        {party?.notes?.length > 40
          ? party?.notes.substring(0, 40) + "..."
          : party?.notes}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        {party?.status === "deleted" ? (
          <div className="flex w-fit bg-red-600 mt-2 px-2 py-1 rounded-md text-white font-bold text-xs">
            Deleted
          </div>
        ) : null}
        {party?.status === "seated" ? (
          <div className="flex w-fit bg-green-700 mt-2 px-2 py-1 rounded-md text-white font-bold text-xs">
            Seated
          </div>
        ) : null}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        <div className="flex space-x-3">
          <button
            className="px-3 py-2 bg-black rounded-md text-white font-bold"
            onClick={() => {
              returnToWaitlist();
            }}
          >
            Return to Waitlist
          </button>
        </div>
      </td>
    </tr>
  );
};

export default PastWaitRow;
